import { http } from './config'
import authHeader from './auth-header';

export default {

  qtd_atendimento: (dataI, dataF, nomeEmpresa, tipo, filtro, opcao, produto) => {

    const params= {
        'data-inicial': dataI,
        'data-final': dataF,
        'empresa': nomeEmpresa,
        'tipo': tipo,
        'filtro': filtro,
        'opcao': opcao,
        'produto': produto,
    }

    return http.get('/funeraria-dash/qtd-atendimento/', {
    params: params,
    headers: authHeader()   
    })  
    },

    qtd_naoconformidade: (dataI, dataF, nomeEmpresa, tipo, filtro, opcao, produto) => {

      const params= {
          'data-inicial': dataI,
          'data-final': dataF,
          'empresa': nomeEmpresa,
          'tipo': tipo,
          'filtro': filtro,
          'opcao': opcao,
          'produto': produto,
      }
  
      return http.get('/funeraria-dash/qtd-conformidade/', {
      params: params,
      headers: authHeader()   
      })  
      },
  

    qtd_atendimento_aberto: (dataI, dataF, nomeEmpresa, tipo, filtro, opcao, produto) => {

      const params= {
          'data-inicial': dataI,
          'data-final': dataF,
          'empresa': nomeEmpresa,
          'tipo': tipo,
          'filtro': filtro,
          'opcao': opcao,
          'produto': produto,
      }
  
      return http.get('/funeraria-dash/qtd-atendimento-aberto/', {
      params: params,
      headers: authHeader()   
      })  
      },

      qtd_atendimento_min: (dataI, dataF, nomeEmpresa, tipo, filtro, opcao, produto) => {

        const params= {
            'data-inicial': dataI,
            'data-final': dataF,
            'empresa': nomeEmpresa,
            'tipo': tipo,
            'filtro': filtro,
            'opcao': opcao,
            'produto': produto,
        }
    
        return http.get('/funeraria-dash/qtd-atendimento-min/', {
        params: params,
        headers: authHeader()   
        })  
        },

      lista_ranking_qtd: (dataI, dataF, nomeEmpresa, tipo, filtro, opcao, produto) => {

        const params= {
            'data-inicial': dataI,
            'data-final': dataF,
            'empresa': nomeEmpresa,
            'tipo': tipo,
            'filtro': filtro,
            'opcao': opcao,
            'produto': produto,
        }

        return http.get('/funeraria-dash/lista-ranking-qtd/', {
        params: params,
        headers: authHeader()   
        })  
        },

        lista_ranking_min: (dataI, dataF, nomeEmpresa, tipo, filtro, opcao, produto) => {

          const params= {
              'data-inicial': dataI,
              'data-final': dataF,
              'empresa': nomeEmpresa,
              'tipo': tipo,
              'filtro': filtro,
              'opcao': opcao,
              'produto': produto,
          }
  
          return http.get('/funeraria-dash/lista-ranking-min/', {
          params: params,
          headers: authHeader()   
          })  
          },

          lista_funeraria: (dataI, dataF, nomeEmpresa, tipo, filtro, opcao, produto) => {

            const params= {
                'data-inicial': dataI,
                'data-final': dataF,
                'empresa': nomeEmpresa,
                'tipo': tipo,
                'filtro': filtro,
                'opcao': opcao,
                'produto': produto,
            }
    
            return http.get('/funeraria-dash/lista-funeraria/', {
            params: params,
            headers: authHeader()   
            })  
            },


         

            
}
